import {environment} from '../../environments/environment';
export const SERVER_API_URL = environment.serverUrl;
export const UPLOAD_IMAGE_API_URL = environment.serverFileUrl;
export const SERVER_FILE_URL = 'http://localhost:8089';


export const AIRLINE_ICONS: {[key: string]: any} = {
    VN: {
      icon: '/assets/airline-logo/smVN.gif',
      iconFull: '/assets/airline-logo/smVN_Full.gif'
    },
    JQ: {
      icon: '/assets/airline-logo/smBL.gif',
      iconFull: '/assets/airline-logo/smBL_Full.gif'
    },
    VJ: {
      icon: '/assets/airline-logo/smVJ.gif',
      iconFull: '/assets/airline-logo/smVJ_Full.gif'
    },
    QH: {
      icon: '/assets/airline-logo/smQH.gif',
      iconFull: '/assets/airline-logo/smQH_Full.gif'
    },
    SQ: {
      icon: '/assets/airline-logo/smSQ.gif',
      iconFull: '/assets/airline-logo/smSQ.gif'
    },
    MI: {
      icon: '/assets/airline-logo/smMI.gif',
      iconFull: '/assets/airline-logo/smMI.gif'
    },
    MH: {
      icon: '/assets/airline-logo/smMH.gif',
      iconFull: '/assets/airline-logo/smMH.gif'
    },
    CX: {
      icon: '/assets/airline-logo/smCX.gif',
      iconFull: '/assets/airline-logo/smCX.gif'
    },
    KA: {
      icon: '/assets/airline-logo/smKA.gif',
      iconFull: '/assets/airline-logo/smKA.gif'
    },
    NH: {
      icon: '/assets/airline-logo/smNH.gif',
      iconFull: '/assets/airline-logo/smNH.gif'
    },
    OD: {
      icon: '/assets/airline-logo/smOD.gif',
      iconFull: '/assets/airline-logo/smOD.gif'
    },
    '3K': {
      icon: '/assets/airline-logo/sm3K.gif',
      iconFull: '/assets/airline-logo/sm3K.gif'
    },
    DEFAULT: {
      icon: 'assets/airline-logo/airline_default1.png'
    }
  };
