import { NgModule } from '@angular/core';
import { PreloadAllModules, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { NavComponent } from './core/components/nav/nav.component';
import { CustomRouteReuseStrategy } from './core/nav-reuse-strategy';
import { NavGuard } from './core/nav.guard';
import { navRoutes } from './nav-routing';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () =>
            import('./pages/login-page/login-page.module').then(
                m => m.LoginPageModule,
            ),
    },
    {
        path: 'image',
        loadChildren: () =>
            import('./pages/image-page/image-page.module').then(
                m => m.ImagePageModule,
            ),
    },
    {
        path: '',
        component: NavComponent,
        children: navRoutes,
        canActivate: [AuthGuard],
        canActivateChild: [NavGuard],
    },    
    // {
    //     path: '**',
    //     redirectTo: '',
    // },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
    providers: [
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    ],
})
export class AppRoutingModule {}
