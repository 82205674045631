import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LogoModule } from '../logo/logo.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material';
import { AppSidebarComponent } from './sidebar/sidebar.component';
import { SidebarCustomComponent } from './sidebar/sidebar.custom.component';
import { AppHeaderComponent } from './header/header.component';
import {MatMenuModule} from '@angular/material/menu';
import { MenuItems } from './menu-items';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
    declarations: [NavComponent, AppHeaderComponent,AppSidebarComponent, SidebarCustomComponent],
    imports: [
        CommonModule,
        RouterModule,
        MatSidenavModule,
        LogoModule,
        FlexLayoutModule,
        MatListModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatExpansionModule,
        MatMenuModule,
        NgxSpinnerModule
    ],
    providers:[MenuItems]
})
export class NavModule {}
