import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

export const DATE_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
    },
};

export const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
    parse: {
      dateInput: 'l, LTS'
    },
    display: {
      dateInput: 'YYYY-MM-DD HH:mm:ss',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
};

@Injectable()
export class DateUtilService {
    constructor() { }

    getCurrentDate(isMonth) {
        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        let dayTemp = day.toString();
        if (dayTemp.length === 1) {
            dayTemp = '0' + dayTemp;
        }

        let monthTemp = month.toString();
        if (monthTemp.length === 1) {
            monthTemp = '0' + monthTemp;
        }

        if (isMonth) {
            return Number(year.toString() + monthTemp);
        }

        return Number(year.toString() + monthTemp + dayTemp);
    }

    // strDate - dd/MM/yyyy
    stringToMomentDate(strDate: string, isTime: boolean) {
        if (!strDate) {
            return undefined;
        }
        try {
            const split = strDate.split(' ');
            const day = Number(split[0].split('/')[0]);
            const month = Number(split[0].split('/')[1]);
            const year = Number(split[0].split('/')[2]);
            let date;
            if (!isTime) {
                date = moment([year, month - 1, day]);
            } else {
                const h = Number(split[1].split(':')[0]);
                const m = Number(split[1].split(':')[1]);
                const s = Number(split[1].split(':')[2]);
                date = moment([year, month - 1, day, h, m, s]);
            }
            return date;
        } catch (error) {
            return undefined
        }
    }

    momentToString(date: any, format: string) {
        if (!date) {
            return '';
        }
        if (!format) {
            format = 'DD/MM/YYYY';
        }
        return moment(date).format(format);
    }

    convertDate(value: string, char: string) : string {
        let splitStr = value.split(char);
        let valueReturn = '';
        if(splitStr[0].length === 1) {
            valueReturn = '0' + splitStr[0] + char;
        }
        if(splitStr[1].length === 1) {
            valueReturn = '0' + splitStr[0] + char;
        }
        if(splitStr[2].length === 1) {
            valueReturn = '0' + splitStr[0];
        }

        return valueReturn;
    }
}
