import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
@NgModule({
    declarations: [ConfirmationDialogComponent],
    entryComponents: [ConfirmationDialogComponent],
    exports:[ConfirmationDialogComponent],
    imports: [
        MaterialModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule
    ],
    providers: [
        { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }
    ]
})
export class ConfirmationDialogModule { }
