import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavModule } from './core/components/nav/nav.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpConfigInterceptor } from './core/services/http/interceptor.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AlertService } from './utils/alert.service';
import { AuthExpiredInterceptor } from './core/services/intercepter/auth-expired.interceptor';
import { NotificationInterceptor } from './core/services/intercepter/notification.interceptor';
import { ConfirmationDialogModule } from './core/components/confirm/confirmation-dialog.module';
import { DateUtilService } from './utils/dateUtils.service';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { UtilService } from './utils/utils.service';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NavModule,
        HttpClientModule,
        NgxSpinnerModule,
        ConfirmationDialogModule,
        ToastrModule.forRoot(),
        NgxMaterialTimepickerModule   
    ],
    providers: [
        AlertService,
        DateUtilService,
        UtilService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true,
            deps: [Injector]
        },        
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true,
            deps: [Injector]
        }
    ],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class AppModule { }
