import { Injectable } from '@angular/core';
import { CrudService } from '../core/services/http/crud.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../core/services/storage/storage.service';
import { StorageKey } from '../core/services/storage/storage.model';
import { SERVER_API_URL } from '../utils/app.constants';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
const { AUTH_TOKEN } = StorageKey;

@Injectable({
    providedIn: 'root',
})
@Injectable({
    providedIn: 'root',
})
export class AuthService extends CrudService {
    endpoint = 'authenticate';
    url = SERVER_API_URL + 'api';
    token: string;
    redirectUrl: string;

    constructor(http: HttpClient, private storage: StorageService, private router: Router,) {
        super(http);
        this.token = this.storage.read(AUTH_TOKEN) || '';
    }

    public async login(username: string, password: string) {
        try {  
            let response = {id_token: ''};          
            // tslint:disable-next-line:object-literal-shorthand
            response = await this.post({ username: username, password:password });
            this.token = response.id_token;
            this.storage.save(AUTH_TOKEN, this.token);
            return this.redirectUrl;
        } catch (error) {
            console.error('Error during login request', error);
            return Promise.reject(error);
        }
    }

    public getToken(): string {
        return this.token;
    }

    public logout() {
        this.token = '';
        this.storage.remove(AUTH_TOKEN); 
        this.router.navigate(['login'], { replaceUrl: true });       
    }

    public isLogged(): boolean {
        return this.token.length > 0;
    }

    resetPassword(mail: string): Observable<any> {
        return this.http.post(SERVER_API_URL + 'api/account/reset-password/init', mail);
    }
}
