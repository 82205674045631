import { Injectable } from '@angular/core';
import { CrudService } from '../core/services/http/crud.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AppConfig } from '../model/app-config.model';
import { SERVER_API_URL } from '../utils/app.constants';
import { Observable } from 'rxjs/internal/Observable';
import { createRequestOption } from '../utils/request-util';
import { AppMenu } from '../model/app-menu.model';
import { AppMenuTree } from '../model/app-menu-tree.model';

type EntityResponseType = HttpResponse<AppMenu>;
type EntityArrayResponseType = HttpResponse<AppMenu[]>;

@Injectable({ providedIn: 'root' })
export class AppMenuService {
    
  public resourceUrl = SERVER_API_URL + 'api/app-menus';

  constructor(protected http: HttpClient) {}

  create(appMenu: AppMenu): Observable<EntityResponseType> {
    return this.http.post<AppMenu>(this.resourceUrl, appMenu, { observe: 'response' });
  }

  update(appMenu: AppMenu): Observable<EntityResponseType> {
    return this.http.put<AppMenu>(this.resourceUrl, appMenu, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<AppMenu>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<AppMenuTree[]>(this.resourceUrl + '/search', { params: options, observe: 'response' });
  }

  queryAssign(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<AppMenuTree[]>(this.resourceUrl + '/searchAssign', { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  getMenuItem(): Observable<any[]> {
    return this.http.get<any[]>(SERVER_API_URL + 'api/app-users/get-menu-item');
  }
}
