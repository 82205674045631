import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  public logout() {
    this.authService.logout();
    this.router.navigate(['login'], { replaceUrl: true });
  }

  public changePassword(){
    this.router.navigate(['change-pass'], { replaceUrl: true });
  }
  public profileAccount(){
    this.router.navigate(['profile'], { replaceUrl: true });
  }
}
