import { Route, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthGuard } from './auth/auth.guard';

export interface NavRoute extends Route {
    path?: string;
    icon?: string;
    group?: string;
    groupedNavRoutes?: NavRoute[];
}

export const sideNavPath = '';

export const navRoutes: NavRoute[] = [
    {
        data: { title: 'Home' },
        icon: 'home',
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/home-page/home-page.module').then(
                m => m.HomePageModule,
            ),
    },
    {
        path: 'config',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/system/system-config/config.module').then(
                m => m.ConfigModule,
            ),
    },
    {
        path: 'menu',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/system/system-menu/menu.module').then(
                m => m.MenuModule,
            ),
    },
    {
        path: 'group',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/system/system-group/group.module').then(
                m => m.GroupModule,
            ),
    },
    {
        path: 'new-category',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/system/system-new-category/new-category.module').then(
                m => m.NewCategoryModule,
            ),
    },
    {
        path: 'news',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/system/news/news.module').then(
                m => m.NewsModule,
            ),
    },
    {
        path: 'system-accounts',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/system/system-account/system-accounts.module').then(
                m => m.SystemAccountsModule,
            ),
    },
    {
        path: 'country',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/system/country/country.module').then(
                m => m.CountryModule,
            ),
    },
    {
        path: 'change-pass',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/system/account/change-pass/change-pass.module').then(
                m => m.ChangePassModule,
            ),
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/system/account/profile/profile.module').then(
                m => m.ProfileModule,
            ),
    },
    {
        path: 'place',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/system/place/place.module').then(
                m => m.PlaceModule,
            ),
    },
    {
        path: 'statistics-revenue',
        canActivate: [AuthGuard],
        loadChildren: () => 
            import('./pages/statistics-report/statistics-with-revenue/statistics-common.module').then(
                m => m.StatisticsCommonModule
            ),
    },
    {
      path: 'statistic-shipment',
      canActivate: [AuthGuard],
      loadChildren: () => 
        import('./pages/statistics-report/statistic-with-shipment/statistic-with-shipment.module').then (
            m => m.StatisticWithShipmentModule
        )  
    },
    // update status order's group
    {
        path: 'bulk-status',
        canActivate: [AuthGuard],
        loadChildren: () => 
            import('./pages/global-order-management/status-global-shipment/global-shipment.module').then(
                m => m.GlobalShipmentModule
            ),
    },
    {
        path: 'ware-housing',
        canActivate: [AuthGuard],
        loadChildren: () => 
            import('./pages/global-order-management/warehouse/warehouse.module').then(
                m => m.WarehouseModule
            )
    },
    {
        path: 'export-customer-order',
        canActivate: [AuthGuard],
        loadChildren: () => 
            import('./pages/global-order-management/customer-orders/customer-orders.module').then(
                m => m.CustomerOrdersModule
            )
    }
];

@Injectable({
    providedIn: 'root',
})
export class NavRouteService {
    navRoute: Route;
    navRoutes: NavRoute[];

    constructor(router: Router) {
        this.navRoute = router.config.find(route => route.path === sideNavPath);
        this.navRoutes = this.navRoute.children
            .filter(route => route.data && route.data.title)
            .reduce((groupedList: NavRoute[], route: NavRoute) => {
                if (route.group) {
                    const group: NavRoute = groupedList.find(navRoute => {
                        return (
                            navRoute.group === route.group &&
                            navRoute.groupedNavRoutes !== undefined
                        );
                    });
                    if (group) {
                        group.groupedNavRoutes.push(route);
                    } else {
                        groupedList.push({
                            group: route.group,
                            groupedNavRoutes: [route],
                        });
                    }
                } else {
                    groupedList.push(route);
                }
                return groupedList;
            }, []);
    }

    public getNavRoutes(): NavRoute[] {
        return this.navRoutes;
    }
}


