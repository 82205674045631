import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children: any[];
}

const MENUITEMS = [
  { state: 'home', name: 'Trang chủ', type: 'link', icon: 'home', children: null },
  {
    state: '#config', name: 'Hệ thống', type: 'link', icon: 'settings', children: [
      { state: 'config', name: 'Cấu hình hệ thống', type: 'link', icon: 'settings', children: null },
      { state: 'group', name: 'Quản lý nhóm quyền', type: 'link', icon: 'group', children: null },
      { state: 'menu', name: 'Quản lý menu hiển thị', type: 'link', icon: 'menu', children: null },      
    ]
  },
  { state: 'system-accounts', name: 'Quản lý tài khoản', type: 'link', icon: 'account_box', children: null },
  {
    state: '#news', name: 'Tin tức', type: 'link', icon: 'web', children: [
      { state: 'new-category', name: 'Chuyên mục tin tức', type: 'link', icon: 'category', children: null },
      { state: 'news', name: 'Quản lý tin tức', type: 'link', icon: 'fiber_new', children: null },
    ]
  },
  {
    state: '#tour', name: 'Tour du lịch', type: 'link', icon: 'wb_sunny', children: [
      { state: 'country', name: 'Danh mục Quốc gia', type: 'link', icon: 'flag', children: null },
      { state: 'place', name: 'Danh mục điểm đến', type: 'link', icon: 'place', children: null },
      { state: 'tour-type', name: 'Danh mục loại hình du lịch', type: 'link', icon: 'category', children: null },
      { state: 'tour-action', name: 'Danh mục hoạt động du lịch', type: 'link', icon: 'av_timer', children: null },
      { state: 'tour-bring-item', name: 'Danh mục vật dụng cần mang', type: 'link', icon: 'movie', children: null },
      { state: 'tour', name: 'Quản lý tour du lịch', type: 'link', icon: 'card_travel', children: null },
    ]
  },
  {
    state: '#customer', name: 'Khách hàng', type: 'link', icon: 'add_shopping_cart', children: [
      { state: 'tour-provider', name: 'Nhà cung cấp tour', type: 'link', icon: 'touch_app', children: null },
      { state: 'tour-customer', name: 'Khách hàng đặt tour', type: 'link', icon: 'how_to_reg', children: null }
    ]
  },
  { state: '#web-content', name: 'Quản lý nội dung web', type: 'link', icon: 'list_alt', children: [
      { state: 'web-content', name: 'Quản lý nội dung', type: 'link', icon: 'list_alt', children: null },
      { state: 'config-customer-feedbacks', name: 'Quản lý phản hồi khách hàng', type: 'link', icon: 'feedback', children: null },
      { state: 'config-office', name: 'Quản lý thông tin văn phòng', type: 'link', icon: 'business', children: null },
      { state: 'media', name: 'Thông tin website', type: 'link', icon: 'business', children: null },
      { state: 'banner', name: 'Quản lý Banner', type: 'link', icon: 'business', children: null }
    ]
  },
  {
    state: '#management', id: 'management', name: 'CRM Management',type: 'link', icon: 'apps', children: [
        {state: 'crm-configuration',id: 'crm-configuration', name: 'Tài khoản các hãng hàng không nội địa', icon: 'settings',
         children: null},
        {state: 'crm-payment',id: 'crm-payment', name: 'Hình thức thanh toán', icon: 'payment', children: null},
        {state: 'crm-fee',id: 'crm-fee', name: 'Quản lý phí dịch vụ', icon: 'launch', children: null},
        {state: 'crm-condition-price',id: 'crm-common', name: 'Điều kiện giá vé', icon: 'settings', children: null},
        {state: 'crm-ticket-area',id: 'crm-ticket-area', name: 'Quản lý khu vực', icon: 'place',children: null},
        {state: 'crm-ticket-place',id: 'crm-ticket-place', name: 'Quản lý điểm đến', icon: 'place',children: null},
        {state: 'ticket-cheap',id: 'ticket-cheap', name: 'Vé máy bay giá rẻ', icon: 'place',children: null},
        {state: 'crm-reservation',id: 'tour-type', name: 'Quản lý đặt chỗ', icon: 'work',children: null},
        {state: 'crm-ticket-export',id: 'tour-type', name: 'Quản lý xuất vé', icon: 'business', children: null},
      ]
  },
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
