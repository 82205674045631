import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
    NavigationService,
    Page,
} from '../../services/navigation/navigation.service';
import { NavRoute } from '../../../nav-routing';
import { AuthService } from '../../../auth/auth.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { Menu } from './menu-items';
import { AppMenuService } from 'src/app/service/app-menu.service';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
    isOpen = true;
    mobileQuery: MediaQueryList;
    navItems: Menu[];

    // tslint:disable-next-line:variable-name
    private _mobileQueryListener: () => void;

    constructor(
        private navigationService: NavigationService,
        private authService: AuthService,
        private appMenuService: AppMenuService,
        private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher
    ) {
        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    ngOnInit() {
        this.appMenuService.getMenuItem().subscribe(data => {
            this.navItems = data;
        })
    }

    public toggleSideNav() {
        this.isOpen = !this.isOpen;
    }

    public getNavigationItems(): NavRoute[] {
        return this.navigationService.getNavigationItems();
    }

    public getActivePage(): Page {
        return this.navigationService.getActivePage();
    }

    public logout() {
        this.authService.logout();        
    }

    public getPreviousUrl(): string[] {
        return this.navigationService.getPreviousUrl();
    }
}
