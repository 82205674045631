import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AlertService {
    constructor(private toasterService: ToastrService) {}

    success(msg: string, tit: string) {
        this.toasterService.success(msg, tit);
    }

    error(msg: string, tit: string) {
        this.toasterService.error(msg, tit);
    }

    info(msg: string, tit: string) {
        this.toasterService.info(msg, tit);
    }

    warning(msg: string, tit: string) {
        this.toasterService.warning(msg, tit);
    }
}
