import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    Input,
    HostBinding
  } from '@angular/core';

import {animate, state, style, transition, trigger} from '@angular/animations'; 
import { MenuItems,Menu } from '../menu-items';
import {Router} from '@angular/router';
@Component({
    selector: 'app-menu-list-item',
    templateUrl: './sidebar.custom.component.html',
    styleUrls: ['./sidebar.custom.component.scss'],
    animations: [
      trigger('indicatorRotate', [
        state('collapsed', style({transform: 'rotate(0deg)'})),
        state('expanded', style({transform: 'rotate(180deg)'})),
        transition('expanded <=> collapsed',
          animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
        ),
      ])
    ]
  })
  export class SidebarCustomComponent  {
    expanded: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: Menu;
  @Input() depth: number;

  constructor(public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  onItemSelected(item: Menu) {
    if (!item.children || !item.children.length) {
      console.log ('route state: ', item.state);
      this.router.navigate([item.state]);
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}